/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
// i18n
import { Trans, withTranslation } from "react-i18next";

import { usePaymentInputs } from "react-payment-inputs";
// import images from "react-payment-inputs/images";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiFormField from "components/sui/SuiFormField";
// Images
import ColorButton from "layouts/confirm/Payment/components/styles/index";

import images from "react-payment-inputs/images";
// Frame layout components

import { Formik } from "formik";

// Actions
import * as app from "actions/app";

// Constrants
import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;

const initialValues = {
  cardNumber: "5125 6800 0152 8551",
  expiryDate: "02 / 25",
  cvc: "999",
  accountHolderName: "John Smith",
};

function SandBox({ transaction, nextStage, errorStage, handleClose, handleBack }) {
  const dispatch = useDispatch();
  const payment = transaction.payments[1];
  const { meta, getCardNumberProps, getCardImageProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;
  // eslint-disable-next-line no-unused-vars
  const [submit, setSubmit] = useState(false);
  const formik = useRef(null);
  const [reject] = useState(false);

  const handleCancel = () => {
    handleClose();
    window.close();
  };

  const validate = (values) => {
    const errors = {};
    if (meta.erroredInputs.cardNumber) {
      errors.cardNumber = meta.erroredInputs.cardNumber;
    }
    if (meta.erroredInputs.expiryDate) {
      errors.expiryDate = meta.erroredInputs.expiryDate;
    }
    if (meta.erroredInputs.cvc) {
      errors.cvc = meta.erroredInputs.cvc;
    }
    if (values.accountHolderName.length === 0) {
      errors.accountHolderName = "Enter an name";
    }
    return errors;
  };

  const onSubmited = (error, result) => {
    setSubmit(false);
    if (error) {
      errorStage(error);
    } else {
      nextStage({ ...transaction, hash: result, status: TRANSACTION_STATUSES.PENDING });
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.pay(payment.id, { ...values, currency: payment.currency }, onSubmited));
  };

  return (
    <Box sx={{ margin: 2 }}>
      <SuiTypography component="p" variant="h4" align="center" width="100%" color="info">
        SandBox
      </SuiTypography>
      <Divider variant="middle" />

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="sandboxForm">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Price
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox display="flex" ml={2}>
                    <SuiTypography variant="h6" fontWeight="medium" mr={1}>
                      {payment.amounts[payment.currency].amount
                        .toString(10)
                        .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")}
                    </SuiTypography>
                    <ColorButton variant="contained" disableElevation>
                      {payment.currency}
                    </ColorButton>
                  </SuiBox>
                </SuiBox>
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SuiFormField
                  {...getCardNumberProps({
                    onBlur: handleBlur,
                    onChange: handleChange,
                    refKey: "inputRef",
                  })}
                  value={values.cardNumber}
                  component={TextField}
                  type="tel"
                  label="Credit card number"
                  inputRef={getCardNumberProps().ref}
                  error={!!touchedInputs.cardNumber && !!erroredInputs.cardNumber}
                  helperText={touchedInputs.cardNumber ? erroredInputs.cardNumber : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <svg {...getCardImageProps({ images })} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={6}>
                <SuiFormField
                  {...getExpiryDateProps({
                    onChange: handleChange,
                    onBlur: handleBlur,
                    refKey: "inputRef",
                  })}
                  inputRef={getExpiryDateProps().ref}
                  value={values.expiryDate}
                  fullWidth
                  type="tel"
                  label="Expiry date"
                  variant="filled"
                  error={touchedInputs.expiryDate && erroredInputs.expiryDate}
                  helperText={touchedInputs.expiryDate ? erroredInputs.expiryDate : null}
                />
              </Grid>
              <Grid item xs={6}>
                <SuiFormField
                  {...getCVCProps({
                    onChange: handleChange,
                    onBlur: handleBlur,
                    refKey: "inputRef",
                  })}
                  inputRef={getCVCProps().ref}
                  fullWidth
                  type="tel"
                  label="CVC"
                  variant="filled"
                  value={values.cvc}
                  error={touchedInputs.cvc && erroredInputs.cvc}
                  helperText={touchedInputs.cvc ? erroredInputs.cvc : null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12}>
                <SuiFormField
                  fullWidth
                  type="text"
                  label="Name on Card"
                  variant="filled"
                  name="accountHolderName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.accountHolderName}
                  error={touched.accountHolderName && errors.accountHolderName}
                  helperText={touched.accountHolderName ? errors.accountHolderName : null}
                />
              </Grid>
            </Grid>
            <Divider variant="middle" />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Grid item xs={5} md={6}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  textTransform="capitalize"
                  ml={3}
                >
                  <Trans values={{ one: 2, two: 3 }}>step-of</Trans>
                </SuiTypography>
              </Grid>
              <Grid item xs={2} md={2}>
                <SuiTypography align="right" variant="caption">
                  <Link
                    underline="hover"
                    onClick={() => {
                      handleBack();
                    }}
                    align="center"
                  >
                    <Trans>back</Trans>
                  </Link>
                </SuiTypography>
              </Grid>

              <Grid item xs={4} md={3} pr={2}>
                {reject ? (
                  <SuiButton
                    name="submit"
                    disabled={submit}
                    component={SuiButton}
                    onClick={() => {
                      handleCancel();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    <Trans>cancel</Trans>
                  </SuiButton>
                ) : (
                  <SuiButton
                    name="submit"
                    disabled={submit}
                    component={SuiButton}
                    onClick={() => {
                      getCardNumberProps().onBlur();
                      getExpiryDateProps().onBlur();
                      getCVCProps().onBlur();
                      formik.current.submitForm();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    <Trans>Pay</Trans>
                  </SuiButton>
                )}
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </Formik>
    </Box>
  );
}

SandBox.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    payments: PropTypes.string.isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    type: PropTypes.number,
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
  }).isRequired,
  nextStage: PropTypes.func.isRequired,
  errorStage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default withTranslation()(SandBox);
