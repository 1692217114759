/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts
import Default from "layouts/default";

import Auth from "layouts/auth";

import Connect from "layouts/connect";
import Confirm from "layouts/confirm";
import Error from "layouts/error";
// Soft UI Dashboard PRO React icons
import Shop from "components/Icons/Shop";
import Document from "components/Icons/Document";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Default",
        key: "default",
        route: "/home/default",
        component: Default,
        authorize: ["authenticated"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Confirm",
    key: "confirm",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Confirm",
        key: "confirm",
        route: "/confirm/:transactionId/:id",
        component: Confirm,
        authorize: ["authenticated"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Connect",
    key: "connect",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Connect",
        key: "connect",
        route: "/connect/:uid/:id",
        component: Connect,
        authorize: ["authenticated"],
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Authentication",
    key: "auth",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Auth",
        key: "auth",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/auth",
            component: Auth,
          },
        ],
      },
      {
        name: "Error",
        key: "error",
        collapse: [
          {
            name: "Error",
            key: "error",
            route: "/error/:code",
            component: Error,
          },
        ],
      },
    ],
  },
];

export default routes;
