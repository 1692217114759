import React, { forwardRef } from "react";

import { withTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import copy from "simple-copy-to-clipboard";

const SuiIconCopyToClipboard = forwardRef(({ text, name, t }, ref) => {
  const [copied, setCopied] = React.useState(false);
  const handleTooltipOpen = () => {
    copy(text);
    setCopied(true);
    const int = setInterval(() => {
      setCopied(false);
      clearInterval(int);
    }, 1000);
  };

  return (
    <>
      <Tooltip
        title={t(copied ? "copy-icon-tooltip-copied" : "copy-icon-tooltip", { name })}
        ref={ref}
      >
        {!copied ? (
          <ContentCopyIcon onClick={handleTooltipOpen} sx={{ cursor: "pointer" }} />
        ) : (
          <CheckCircleOutlineIcon />
        )}
      </Tooltip>{" "}
    </>
  );
});

SuiIconCopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SuiIconCopyToClipboard);
