/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { connect } from "react-redux";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// i18m
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import MenuList from "@mui/material/MenuList";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiNavbarMenu from "components/sui/SuiNavbarMenu";
import SuiCountryIcon from "components/sui/SuiCountryIcon";
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

// Soft UI Dashboard PRO React example components

import borders from "assets/theme/base/borders";

const { borderColor, borderRadius } = borders;

function CurrencyMenu({ anchorEl, close, mobileMenu, countries }) {
  const renderDocsMenuRoute = () => (
    <MenuList autoFocusItem={anchorEl} id="composition-menu" aria-labelledby="composition-button">
      {Object.keys(countries).map((name) => (
        <Grid item xs={12}>
          <SuiBox
            display="flex"
            alignItems="left"
            p={1}
            onClick={() => {
              close(name);
            }}
            sx={{
              "&:hover": { background: "#eceeef", borderRadius: borderRadius.lg, borderColor },
              cursor: "pointer",
            }}
          >
            <SuiCountryIcon code={countries[name]} />
            <SuiBox display="flex" flexDirection="column" lineHeight={1} ml={1}>
              <SuiTypography variant="button" fontWeight="medium">
                {" "}
                {name}
              </SuiTypography>
              <SuiTypography variant="caption" fontWeight="medium">
                <Trans>{`currencies:${name}`}</Trans>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
      ))}
    </MenuList>
  );

  return mobileMenu ? (
    renderDocsMenuRoute()
  ) : (
    <SuiNavbarMenu
      anchorEl={anchorEl}
      close={() => {
        close();
      }}
    >
      {renderDocsMenuRoute()}
    </SuiNavbarMenu>
  );
}

// Setting default values for the props of DocsMenu
CurrencyMenu.defaultProps = {
  mobileMenu: false,
  anchorEl: false,
  close: false,
};

// Typechecking props for the DocsMenu
CurrencyMenu.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CurrencyMenu));
