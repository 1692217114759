/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// react-router-dom components
import { useParams } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// Frame layout components
import BasicLayout from "layouts/components/BasicLayout";
import Error from "components/layout/Error";
import * as app from "actions/app";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

function Connect({ t }) {
  const dispatch = useDispatch();
  const { uid } = useParams();
  const { id } = useParams();
  const [application, setApp] = useState(false);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const onSubmited = (_error, result) => {
    setSubmit(false);
    if (result) {
      window.close();
    }
  };

  const handleYes = () => {
    setSubmit(true);
    dispatch(app.connect(uid, parseInt(id, 10), onSubmited));
  };

  const handleNo = () => {
    setSubmit(false);
    window.close();
  };

  useEffect(() => {
    dispatch(
      app.application(uid, (_error, result) => {
        if (error) {
          setError(_error);
        } else {
          setApp(result);
        }
        dispatch(app.loadingOff());
      })
    );
  }, []);

  return (
    <BasicLayout title={t("connect.title")} description={t("connect.subtitle")} image={curved9}>
      <Grid item>
        <Card>
          <SuiBox p={3} mb={1} textAlign="center">
            <SuiTypography variant="h5" fontWeight="medium">
              <Trans>connect</Trans>
            </SuiTypography>
          </SuiBox>
          <SuiBox p={3}>
            <Error
              error={error}
              color="primary"
              onClick={() => {
                dispatch(app.errorClose());
              }}
            />
            <SuiBox>
              <SuiBox
                display="flex"
                alignItems="center"
                sx={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}
              >
                <SuiTypography variant="body1" fontWeight="regular" verticalAlign="middle">
                  <Trans>connect.question</Trans> <b>{application.name}</b>?
                </SuiTypography>
              </SuiBox>
              <SuiBox mt={4} mb={1}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={submit}
                  onClick={handleYes}
                >
                  <Trans>yes</Trans>
                </SuiButton>
              </SuiBox>
              <SuiBox mt={2} mb={1}>
                <SuiButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  disabled={submit}
                  onClick={handleNo}
                >
                  <Trans>no</Trans>
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Card>
      </Grid>
    </BasicLayout>
  );
}

Connect.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Connect);
