/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-multi-assign */
import ActionTypes from "constants/ActionTypes";

const initialState = {
  initialized: false,
  loading: false,
  error: undefined,
  info: undefined,
  render: true,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_RENDER:
      return {
        ...state,
        render: !state.render,
      };
    case ActionTypes.APP_INIT:
      return {
        ...state,
        initialized: true,
      };
    case ActionTypes.APP_INIT_FAILED:
      return {
        ...state,
        initialized: false,
        error: action.payload.error,
      };

    case ActionTypes.AUTH_SIGN_IN:
      return {
        ...state,
      };

    case ActionTypes.APP_SIGN_OUT:
      return {
        ...state,
      };
    case ActionTypes.APP_LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.APP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.APP_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    case ActionTypes.APP_ERROR_CLOSE:
      return {
        ...state,
        error: undefined,
      };

    case ActionTypes.APP_INFO:
      return {
        ...state,
        info: action.payload.info,
      };

    case ActionTypes.APP_INFO_CLOSE:
      return {
        ...state,
        info: undefined,
      };
    case ActionTypes.AUTH_RESET_PASSWORD:
      return {
        ...state,
        info: action.payload.info,
        error: action.payload.error,
      };

    case ActionTypes.APP_CHANGE_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    default:
      return state;
  }
}
