"use statics";

export default {
  ETHER: "1000000000000000000",
  GWEI: "1000000000",
  DATE_AND_TIME_FORMAT: "DD MMM YYYY hh:mm A",
  DATE_FORMAT: "DD MMM YYYY",
  TIME_FORMAT: "hh:mm A",
  CURRENCIES_COUNTRIES_CODES: { USD: "us", EUR: "european_union", PLN: "pl" },
  TRANSACTION_TYPES: {
    SEND: 50,
    CONTRACT_INTERACTION: 60,
    CONTRACT_DEPLOYMENT: 70,
    TRANSFER_TOKEN: 80,
  },
  TRANSACTION_STATUSES: {
    NEW: 0,
    REJECTED: 1,
    PENDING: 2,
    FAILED: 3,
    FINISHED: 4,
  },
  DEFAULT_GAS_LIMIT: {
    WEB3: {
      SEND: 21000,
      TRANSFER_TOKEN: 63000,
    },
  },
  AUTHENTICATORS: {
    GOOGLE: 0,
    SMS: 1,
  },
  REFLINK_TYPES: {
    SIGN_IN: 10,
    SIGN_UP: 20,
    CREATE_PROFILE: 30,
    RESET_PASSWORD: 70,
    CHANGE_GOOGLE_AUTHENTICATOR: 100,
    ENABLE_GOOGLE_AUTHENTICATOR: 200,
    CHANGE_SMS_AUTHENTICATOR: 101,
    EMABLE_SMS_AUTHENTICATOR: 201,
  },
};
