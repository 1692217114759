/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// prettier-ignore

const { errors } = require("web3-core-helpers");
const XHR2 = require("xhr2-cookies").XMLHttpRequest; // jshint ignore: line
const http = require("http");
const https = require("https");
const Web3 = require("web3");
const Jsonrpc = require("./lib/jsonrpc");
const MyDappWalletWeb3Provider = require("./web3/provider");
const formatter = require("./lib/formatters");
// const MyDappWalletWeb3 = require("mydappwallet/web3/web3");
// const Method = require("web3-core-method");
// var abi = require('web3-eth-abi');

function setCrossSubdomainCookie(name, value, days) {
  const assign = `${name}=${escape(value)};`;
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()};`;
  const path = "path=/;";
  const domain = `domain=${document.domain.replace(/^[^.]+\./g, "")};`;
  document.cookie = assign + expires + path + domain;
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const MyDappWalletManager = function MyDappWalletManager(host, apiKey, options, callback) {
  this.withCredentials = options.withCredentials || false;
  this.timeout = options.timeout || 0;
  this.headers = options.headers || [];
  this.apiKey = apiKey;
  this.sessionId = 0;
  this.user = undefined;
  this.settings = {};
  this.agent = options.agent;
  this.redirect = options.redirect;
  this.connected = false;

  // keepAlive is true unless explicitly set to false
  const keepAlive = options.keepAlive !== false;
  this.host = host || "http://localhost:8545";
  if (!this.agent) {
    if (this.host.substring(0, 5) === "https") {
      this.httpsAgent = new https.Agent({ keepAlive });
    } else {
      this.httpAgent = new http.Agent({ keepAlive });
    }
  }
  this.headers.push({ name: "ApiKey", value: apiKey });
  this.init(callback);
  this.method = this.method.bind(this);
  this.method("profile");
  this.method("profile_edit");
  this.method("book_add");
  this.method("book_delete");
  this.method("book_edit");
  this.method("book_list");
  this.method("token_list");
  this.method("token_add");
  this.method("token_delete");
  this.method("exchange_rates");
};

MyDappWalletManager.prototype.init = function (callback) {
  const _this = this;
  // this.sessionId = window.localStorage.getItem("SessionId");
  // this.machineId = window.localStorage.getItem("MachineId");
  this.sessionId = getCookie("SessionId");
  this.machineId = getCookie("MachineId");
  if (this.sessionId) {
    this.send(
      { jsonrpc: "2.0", method: "init" },
      _this._jsonrpcResultCallback((error, result) => {
        if (result) {
          _this.sessionId = result.sessionId;
          _this.headers.push({ name: "SessionId", value: result.sessionId });
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
        } else {
          _this.sessionId = undefined;
          _this.wallet = undefined;
          _this.user = undefined;
          _this.settings = {};
          window.localStorage.removeItem("SessionId");
        }
        window.setTimeout(() => {
          callback(error, result);
        }, 0);
      })
    );
  } else {
    window.setTimeout(() => {
      callback(false, true);
    }, 0);
  }
};

MyDappWalletManager.prototype.sign_in = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter({ ...params, machineId: _this.machineId });
  this.send(
    { jsonrpc: "2.0", method: "sign_in", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      if (!error) {
        if (result.status === 1) {
          _this.sessionId = result.sessionId;
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
          setCrossSubdomainCookie("SessionId", result.sessionId, 1);
          // window.localStorage.setItem("SessionId", result.sessionId);
        }
        setCrossSubdomainCookie("MachineId", result.machineId, 365);
        // window.localStorage.setItem("MachineId", result.machineId);
      }
      callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.sign_in2 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "sign_in2", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.sign_in3 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "sign_in3", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      if (!error) {
        if (result.status === 1) {
          _this.sessionId = result.sessionId;
          _this.machineId = result.machineId;
          _this.wallet = result.wallet;
          _this.user = result.user;
          _this.settings = result.settings;
          setCrossSubdomainCookie("SessionId", result.sessionId, 1);
          setCrossSubdomainCookie("MachineId", result.machineId, 365);
          // window.localStorage.setItem("SessionId", result.sessionId);
          // window.localStorage.setItem("MachineId", result.machineId);
        }
      }
      callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.logout = function (callback) {
  const _this = this;

  this.send(
    { jsonrpc: "2.0", method: "logout" },
    _this._jsonrpcResultCallback((error, result) => {
      _this.sessionId = undefined;
      _this.user = undefined;
      window.localStorage.setItem("SessionId", undefined);
      if (callback) callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.sign_up = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "sign_up", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.sign_up2 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "sign_up2", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.sign_up3 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "sign_up3", params: [params] },
    _this._jsonrpcResultCallback((error, result, info) => {
      if (!error) {
        _this.sessionId = result.sessionId;
        _this.wallet = result.wallet;
        _this.user = result.user;
        window.localStorage.setItem("SessionId", result.sessionId);
      }
      callback(error, result, info);
    })
  );
  return true;
};

MyDappWalletManager.prototype.change_settings = function (params, callback) {
  const _this = this;
  this.send(
    { jsonrpc: "2.0", method: "change_settings", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      _this.settings = params;
      if (callback) callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.authenticator = function (callback) {
  const _this = this;
  this.send(
    { jsonrpc: "2.0", method: "authenticator" },
    _this._jsonrpcResultCallback((error, result) => {
      if (error) {
        this.user.newAuthenticator = false;
      }
      callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.forgot_password = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "forgot_password", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      if (callback) callback(error, result);
    })
  );
  return true;
};

MyDappWalletManager.prototype.reset_password = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "reset_password", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.reset_password2 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "reset_password2", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.change_password = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "change_password", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.reflink = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "reflink", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.connect = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "connect", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.app_list = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "app_list", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.app = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "app", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.app_add = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "app_add", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.app_edit = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "app_edit", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.contract = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "contract", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.contract_add = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "contract_add", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.contract_edit = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "contract_edit", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.contract_delete = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "contract_delete", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.transaction = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "transaction", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.transaction_list = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "transaction_list", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.buy = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "buy", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.pay = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  const payload = Jsonrpc.toPayload("pay", [params]);
  this.send(payload, _this._jsonrpcResultCallback(callback, payload));
  return true;
};

MyDappWalletManager.prototype.payment = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "payment", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.confirm = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  const payload = Jsonrpc.toPayload("confirm", [params]);
  this.send(payload, _this._jsonrpcResultCallback(callback, payload));
  return true;
};

MyDappWalletManager.prototype.reject = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "reject", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_list = function (callback) {
  const _this = this;
  this.send(
    { jsonrpc: "2.0", method: "authenticator_list", params: [{}] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_edit = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_edit", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};
MyDappWalletManager.prototype.authenticator_edit2 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_edit2", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_disable = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_disable", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_enable = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_enable", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_enable2 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_enable2", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_enable3 = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_enable3", params: [params] },
    _this._jsonrpcResultCallback(callback)
  );
  return true;
};

MyDappWalletManager.prototype.authenticator_confirm = function (params, callback) {
  const _this = this;
  params = formatter.hexParamsFormatter(params);
  this.send(
    { jsonrpc: "2.0", method: "authenticator_confirm", params: [params] },
    _this._jsonrpcResultCallback((error, result) => {
      if (callback) callback(error, result);
    })
  );

  return true;
};

MyDappWalletManager.prototype.method = function (name) {
  this[name] = function (params, callback) {
    const _this = this;
    params = formatter.hexParamsFormatter(params);
    this.send(
      { jsonrpc: "2.0", method: name, params: [params] },
      _this._jsonrpcResultCallback((error, result) => {
        if (callback) callback(error, result);
      })
    );
    return true;
  };
};

MyDappWalletManager.prototype.event = function (event, err, result) {
  if (result && result.info) {
    event.info = result.info;
  }
  if (err) {
    event.error = err;
  } else if (result && result.error) {
    event.error = result.error;
  } else if (!Jsonrpc.isValidResponse(result)) {
    event.result = result;
  } else {
    event.result = result.result;
  }

  switch (event.target) {
    case "mdw-inpage":
      if (window.opener) {
        window.opener.postMessage(event, "*");
      }
      break;
    default:
  }
};

MyDappWalletManager.prototype.web3 = function (host) {
  const provider = new MyDappWalletWeb3Provider(host, this);
  const web3 = new Web3(provider);
  return web3;
};

MyDappWalletManager.prototype._prepareRequest = function () {
  let request;
  // the current runtime is a browser
  if (typeof XMLHttpRequest !== "undefined") {
    request = new XMLHttpRequest();
  } else {
    request = new XHR2();
    const agents = {
      httpsAgent: this.httpsAgent,
      httpAgent: this.httpAgent,
      baseUrl: this.baseUrl,
    };
    if (this.agent) {
      agents.httpsAgent = this.agent.https;
      agents.httpAgent = this.agent.http;
      agents.baseUrl = this.agent.baseUrl;
    }
    request.nodejsSet(agents);
  }

  request.open("POST", this.host, true);
  request.setRequestHeader("ApiKey", this.apiKey);
  if (this.sessionId) request.setRequestHeader("SessionId", this.sessionId);
  request.setRequestHeader("Content-Type", "application/json");
  //
  request.timeout = this.timeout;
  request.withCredentials = this.withCredentials;
  if (this.headers) {
    this.headers.forEach((header) => {
      request.setRequestHeader(header.name, header.value);
    });
  }
  return request;
};

MyDappWalletManager.prototype.send = function (payload, callback) {
  const options = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  const headers = {};
  let controller;

  if (typeof AbortController !== "undefined") {
    controller = new AbortController();
  } else if (typeof window !== "undefined" && typeof window.AbortController !== "undefined") {
    // Some chrome version doesn't recognize new AbortController(); so we are using it from window instead
    // https://stackoverflow.com/questions/55718778/why-abortcontroller-is-not-defined
    controller = new window.AbortController();
  }

  if (typeof controller !== "undefined") {
    options.signal = controller.signal;
  }

  // the current runtime is node
  if (typeof XMLHttpRequest === "undefined") {
    // https://github.com/node-fetch/node-fetch#custom-agent
    const agents = { httpsAgent: this.httpsAgent, httpAgent: this.httpAgent };

    if (this.agent) {
      agents.httpsAgent = this.agent.https;
      agents.httpAgent = this.agent.http;
    }

    if (this.host.substring(0, 5) === "https") {
      options.agent = agents.httpsAgent;
    } else {
      options.agent = agents.httpAgent;
    }
  }

  if (this.headers) {
    this.headers.forEach((header) => {
      headers[header.name] = header.value;
    });
  }

  if (this.sessionId) headers.SessionId = this.sessionId;

  // Default headers
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  // As the Fetch API supports the credentials as following options 'include', 'omit', 'same-origin'
  // https://developer.mozilla.org/en-US/docs/Web/API/fetch#credentials
  // To avoid breaking change in 1.x we override this value based on boolean option.
  if (this.withCredentials) {
    options.credentials = "include";
  } else {
    options.credentials = "omit";
  }

  options.headers = headers;

  if (this.timeout > 0 && typeof controller !== "undefined") {
    this.timeoutId = setTimeout(() => {
      controller.abort();
    }, this.timeout);
  }

  const success = function (response) {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
    }

    // Response is a stream data so should be awaited for json response
    response
      .json()
      .then((data) => {
        if (data.event) {
          this.event(data.event, undefined, data);
        }
        callback(null, data);
      })
      .catch(() => {
        callback(errors.InvalidResponse(response));
      });
  };

  const failed = function (error) {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
    }

    if (error.name === "AbortError") {
      callback(errors.ConnectionTimeout(this.timeout));
    }

    callback(errors.InvalidConnection(this.host));
  };

  fetch(this.host, options).then(success.bind(this)).catch(failed.bind(this));
};

/*
MyDappWalletManager.prototype.send = function (payload, callback) {
  const _this = this;
  const request = this._prepareRequest();
  request.onreadystatechange = function () {
    if (request.readyState === 4 && request.timeout !== 1) {
      let result = request.responseText;
      let error = null;
      try {
        result = JSON.parse(result);
      } catch (e) {
        error = _this.InvalidResponse(request.responseText);
      }
      _this.connected = true;
      if (result.event) {
        _this.event(result.event, error, result);
      }
      callback(error, result);
    }
  };
  request.ontimeout = function () {
    _this.connected = false;
    callback(errors.ConnectionTimeout(this.timeout));
  };
  try {
    request.send(JSON.stringify(payload));
  } catch (error) {
    this.connected = false;
    callback(errors.InvalidConnection(this.host));
  }
};
*/
// eslint-disable-next-line no-underscore-dangle
MyDappWalletManager.prototype._jsonrpcResultCallback = function (callback, payload) {
  const _this = this;
  return function (err, result) {
    if (result && result.id && payload.id !== result.id) {
      return callback(
        new Error(
          `Wrong response id ${result.id} (expected: ${payload.id}) in ${JSON.stringify(payload)}`
        )
      );
    }
    if (err) {
      return callback(err);
    }
    if (result && result.error) {
      return callback(_this.ErrorResponse(result));
    }
    if (!Jsonrpc.isValidResponse(result)) {
      return callback(errors.InvalidResponse(result));
    }
    callback(null, result.result, result.info);
  };
};

MyDappWalletManager.prototype.InvalidResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : `Invalid JSON RPC response: ${JSON.stringify(result)}`;
  const err = new Error(message);
  const code = !!result.error && !!result.error.code ? result.error.code : 500;
  err.code = code;
  return err;
};

MyDappWalletManager.prototype.ErrorResponse = function (result) {
  const message =
    !!result && !!result.error && !!result.error.message
      ? result.error.message
      : JSON.stringify(result);
  const data = !!result.error && !!result.error.data ? result.error.data : null;
  const err = new Error(message);
  err.data = data;
  const code = !!result.error && !!result.error.code ? result.error.code : null;
  err.code = code;
  return err;
};

module.exports = MyDappWalletManager;
