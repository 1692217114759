/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";

// react-router-dom components
import { useParams } from "react-router-dom";

import { PropTypes } from "prop-types";
// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiSpinner from "components/sui/SuiSpinner";
import SuiAlert from "components/sui/SuiAlert";
// Soft UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Frame layout components
import BasicLayout from "layouts/components/BasicLayout";

// components
import Payment from "layouts/confirm/Payment";
import Final from "layouts/confirm/Final";
// Images
import errorIcon from "assets/images/icons/error.svg";

// Actions
import * as app from "actions/app";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;

const { values } = breakpoints;

const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

function Confirm({ t }) {
  const dispatch = useDispatch();
  const { transactionId } = useParams();
  const [stage, setStage] = useState(0);
  const [transaction, setTransaction] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => {
    dispatch(app.reject("transaction", transactionId, () => {}));
  };
  const nextStage = (transaction) => {
    setTransaction(transaction);
    setStage(stage + 1);
  };
  const lastStage = () => {
    setStage(stage - 1);
  };
  const errorStage = (error) => {
    setError(error);
    setStage(400);
  };

  useEffect(() => {
    dispatch(app.loadingOn());
    bindEvent(window, "beforeunload", handleClose);
    // setCloseEvent([handleClose]);
    dispatch(
      app.transaction(parseInt(transactionId, 10), (_error, result) => {
        if (_error) {
          setError(_error);
          setStage(400);
        } else {
          if (result.payments && result.payments[1]) {
            const { currency } =
              result.payments[1].amounts[Object.keys(result.payments[1].amounts)[0]];
            setTransaction({
              ...result,
              payments: {
                ...result.payments,
                1: { ...result.payments[1], currency },
              },
            });
          } else {
            setTransaction(result);
          }
          setStage(1);
        }
        dispatch(app.loadingOff());
      })
    );
  }, []);

  return (
    <BasicLayout title={t("confirm.title")} description={t("confirm.subtitle")} image={curved9}>
      <Grid item>
        {stage === 0 && (
          <SuiBox py={3}>
            <Grid container>
              <Grid item>
                <SuiBox mb={3} p={1}>
                  <SuiTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    <Trans>frames:confirm-title</Trans>
                  </SuiTypography>
                </SuiBox>
              </Grid>
              <Card sx={{ width: "100%" }}>
                <SuiSpinner />
              </Card>
            </Grid>
          </SuiBox>
        )}
        {stage === 400 && (
          <Card>
            <Grid container direction="row" justifyContent="center" alignItems="right">
              <Grid spacing={2} item xs={12}>
                <SuiBox mb={3} p={1}>
                  <SuiTypography
                    variant={window.innerWidth < values.sm ? "h4" : "h3"}
                    textTransform="capitalize"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    error
                  </SuiTypography>
                </SuiBox>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SuiBox
                    component="img"
                    src={errorIcon}
                    alt="Rocketship"
                    mb={3}
                    width="200px"
                    align="center"
                  />
                </Grid>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SuiBox>
                    <SuiAlert alert={error} color="primary">
                      <Trans>{`error:${error.code}`}</Trans>
                    </SuiAlert>
                  </SuiBox>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        )}
        {stage === 1 && (
          <Card>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Payment
                  transaction={transaction}
                  nextStage={nextStage}
                  errorStage={errorStage}
                  handleClose={handleClose}
                  setTransaction={setTransaction}
                />
              </Grid>
            </Grid>
          </Card>
        )}
        {stage === 2 && (
          <Card>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Final
                  transaction={transaction}
                  setTransaction={setTransaction}
                  finish={setStage}
                />
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
    </BasicLayout>
  );
}

Confirm.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Confirm);
