/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// react-router-dom components
import { Redirect } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import authenticationEN from "locales/en/authentication.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiCodeInput from "components/sui/SuiCodeInput";
import SuiSpinner from "components/sui/SuiSpinner";

// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";
import Error from "components/layout/Error";
import Info from "components/layout/Info";
import Spinner from "components/layout/Spinner";
// Images
import curved7 from "assets/images/curved-images/curved7.jpg";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

// Constants
import Constants from "constants/Constants";

const { REFLINK_TYPES } = Constants;

const initialValues = {
  code: "",
};

function SignUp2({ refId, setRefId, changeView, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
    dispatch(
      app.refLink({ refId, type: REFLINK_TYPES.REGISTER_ACCOUNT }, (error) => {
        if (error) {
          setRedirect(`/error/${error.code}`);
        } else {
          setLoading(false);
        }
      })
    );
  }, []);

  const handleChangeCode = (e) => {
    formik.current.setFieldValue("code", e);
  };

  const onSubmited = (_error, result) => {
    if (_error) {
      switch (_error.code) {
        case 3500:
          dispatch(app.error({ code: 3501 }));
          changeView("sign-in");
          break;
        case 8200:
          formik.current.setFieldValue("code", initialValues.code, false);
          formik.current.errors.code = t("errors:8200");
          codeInput.current.reset();
          break;
        case 8300:
          dispatch(app.error(_error));
          changeView("sign-in");
          break;
        default:
          dispatch(app.error(_error));
      }
      setSubmit(false);
    } else {
      setRefId(result.refId);
      changeView("sign-up3");
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.infoClose());
    dispatch(app.errorClose());
    dispatch(authentication.signUp2({ ...values, refId }, onSubmited));
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      code: Yup.string()
        .required(t("yup:required", { name: t("code") }))
        .min(6, t("yup:min", { name: t("code"), char: t("6") })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };
  if (redirect) return <Redirect to={redirect} />;
  if (loading) return <Spinner />;
  return (
    <BasicLayout
      title={t("authentication:signup2.title")}
      description={t("authentication:signup2.subtitle")}
      image={curved7}
    >
      <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, touched, handleSubmit }) => (
            <Card>
              <Form onSubmit={handleSubmit} noValidate name="registerForm">
                <SuiBox p={3} mb={1} textAlign="center">
                  <SuiTypography variant="h5" fontWeight="medium">
                    <Trans>authentication:confirm-phone</Trans>
                  </SuiTypography>
                </SuiBox>
                <SuiBox pt={2} pb={3} px={3}>
                  <Info
                    info={appStore.info}
                    color="info"
                    onClick={() => {
                      dispatch(app.infoClose());
                    }}
                  />
                  <Error
                    error={appStore.error}
                    color="primary"
                    onClick={() => {
                      dispatch(app.errorClose());
                    }}
                  />
                  <SuiBox>
                    <SuiBox>
                      <SuiTypography variant="caption" component="div">
                        Enter the secret code
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiCodeInput
                        name="code"
                        type="number"
                        ref={codeInput}
                        fields={6}
                        value={values.code}
                        onChange={handleChangeCode}
                        isValid={!(touched.code && errors.code)}
                        filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                        filterCharsIsWhitelist
                        pattern="[0-9]"
                        helperText={errors.code}
                      />
                    </SuiBox>
                    {submit && (
                      <SuiBox mt={1} mb={1}>
                        {" "}
                        <SuiSpinner size={50} minHeight={50} />{" "}
                      </SuiBox>
                    )}
                    <SuiBox mt={4} mb={1}>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={submit}
                        onClick={() => {
                          formik.current.submitForm();
                        }}
                      >
                        <Trans>confirm</Trans>
                      </SuiButton>
                    </SuiBox>
                    <SuiBox mt={3} textAlign="center">
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Already have an account?&nbsp;
                        <SuiTypography
                          onClick={() => {
                            changeView("sign-in");
                          }}
                          variant="button"
                          color="dark"
                          fontWeight="bold"
                          textGradient
                          disabled={submit}
                        >
                          <Trans>authentication:signin</Trans>
                        </SuiTypography>
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </Form>
            </Card>
          )}
        </Formik>
      </Grid>
    </BasicLayout>
  );
}

SignUp2.propTypes = {
  refId: PropTypes.string.isRequired,
  setRefId: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SignUp2);
