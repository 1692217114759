/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// react-router-dom components
import { Redirect } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import { Trans, withTranslation } from "react-i18next";

import * as Yup from "yup";
import { Formik, Form } from "formik";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";
import SuiButton from "components/sui/SuiButton";

// Authentication layout components
import Error from "components/layout/Error";
import Info from "components/layout/Info";
import Socials from "layouts/components/Socials";
import Separator from "layouts/components/Separator";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";

const initialValues = {
  username: "",
  password: "",
};

// eslint-disable-next-line no-unused-vars
function Stage0({ changeView, nextStage, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);

  useEffect(
    () => () => {
      dispatch(app.errorClose());
    },
    []
  );

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      username: Yup.string()
        .min(3, t("yup:min", { name: t("username"), char: 3 }))
        .required(t("yup:required", { name: t("username") })),
      password: Yup.string()
        .required(t("yup:required", { name: t("password") }))
        .min(8, t("yup:min", { name: t("password"), char: 8 })),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (_error, result) => {
    if (_error) {
      switch (_error.code) {
        case 500:
          setRedirect("/frames/error/500");
          break;
        case 2100:
          formik.current.setValues({ username: "", password: "" }, false);
          formik.current.setTouched({ username: true, password: false }, false);
          break;
        default:
          dispatch(app.error(_error));
      }
      formik.current.errors.username = t(`errors:${_error.code}`);
    } else if (result.status === 0) {
      nextStage(result.refId);
    }
    dispatch(app.render());
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.errorClose());
    dispatch(app.infoClose());
    dispatch(authentication.signIn(values, onSubmited));
    return true;
  };

  if (redirect) return <Redirect to={redirect} />;
  const { mydappwallet } = window;
  const { user } = mydappwallet;
  return (
    <BasicLayout
      title={t("authentication:welcome")}
      description={t("authentication:signin.title")}
      image={curved9}
    >
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Card>
            {/* Form Fields */}
            {JSON.stringify(user)}
            <Form onSubmit={handleSubmit} noValidate name="signInForm">
              <SuiBox p={3} mb={1} textAlign="center">
                <SuiTypography variant="h5" fontWeight="medium">
                  <Trans>authentication:signin.subtitle</Trans>
                </SuiTypography>
              </SuiBox>
              <SuiBox mb={2}>
                <Socials />
              </SuiBox>
              <SuiBox p={3}>
                <Info
                  info={appStore.info}
                  color="info"
                  onClick={() => {
                    dispatch(app.infoClose());
                  }}
                />
                <Error
                  error={appStore.error}
                  color="primary"
                  onClick={() => {
                    dispatch(app.errorClose());
                  }}
                />
                <SuiBox>
                  <SuiBox mb={2}>
                    <SuiInput
                      name="username"
                      placeholder={t("username")}
                      error={!!touched.username && !!errors.username}
                      helperText={errors.username}
                      onChange={handleChange}
                      value={values.username}
                      disabled={submit}
                      onBlur={handleBlur}
                    />
                  </SuiBox>
                  <SuiBox mb={2}>
                    <SuiInput
                      name="password"
                      type="password"
                      placeholder={t("password")}
                      error={!!touched.password && !!errors.password}
                      helperText={errors.password}
                      onChange={handleChange}
                      value={values.password}
                      disabled={submit}
                      onBlur={handleBlur}
                    />
                  </SuiBox>
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={() => {
                        formik.current.submitForm();
                      }}
                      disabled={submit}
                    >
                      <Trans>authentication:signin</Trans>
                    </SuiButton>
                  </SuiBox>
                  <Separator />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                      <SuiButton
                        onClick={() => {
                          changeView("sign-up");
                        }}
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={submit}
                      >
                        <Trans>authentication:signup</Trans>
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={12} xl={12} xxl={6} mt={1} mb={1}>
                      <SuiButton
                        onClick={() => {
                          changeView("reset-password");
                        }}
                        variant="gradient"
                        color="secondary"
                        fullWidth
                        disabled={submit}
                      >
                        <Trans>authentication:reset</Trans>
                      </SuiButton>
                    </Grid>
                  </Grid>
                </SuiBox>
              </SuiBox>
            </Form>
          </Card>
        )}
      </Formik>
    </BasicLayout>
  );
}

Stage0.propTypes = {
  changeView: PropTypes.func.isRequired,
  nextStage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Stage0);
