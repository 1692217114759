/* eslint-disable react/jsx-props-no-spreading */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { jsx as _jsx } from "react/jsx-runtime";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiInput from "components/sui/SuiInput";

function SuiFormField({ label, component, ...rest }) {
  return (
    <SuiBox display="flex" flexDirection="column" justifyContent="flex-start" height="100%">
      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </SuiTypography>
      </SuiBox>
      {_jsx(component, { ...rest })}
    </SuiBox>
  );
}

// Setting default values for the props of FormField
SuiFormField.defaultProps = {
  label: " ",
  component: SuiInput,
};

// Typechecking props for FormField
SuiFormField.propTypes = {
  label: PropTypes.string,
  component: PropTypes.elementType,
};

export default SuiFormField;
