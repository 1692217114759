/* eslint-disable no-unused-vars */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import Spinner from "components/layout/Spinner";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

import "assets/theme/main.css";

// RTL plugins

import routes from "routes";
import Error404 from "layouts/error/404";
import Auth from "layouts/auth";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

import * as roleMatcher from "context/roleMatcher";

// Images
import brand from "assets/images/logo-ct.png";

export default function Theme() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const loading = useSelector((state) => state.appStore.loading);
  const { pathname } = useLocation();
  const { mydappwallet } = window;
  let userRoles = [];
  const { user } = mydappwallet;

  if (user) {
    // eslint-disable-next-line no-unused-vars
    userRoles = user.roles;
  }
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (!user && !route.route.startsWith("/auth") && !route.route.startsWith("/error")) {
          return <Route exact path={route.route} component={Auth} key={route.key} />;
        }
        if (route.authorize && roleMatcher.rolesMatched(route.authorize, userRoles) === false) {
          return <Route exact path={route.route} component={Error404} key={route.key} />;
        }

        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      {loading && <Spinner />}
      <SuiBox mx={layout === "default" ? "auto" : 0} maxWidth={layout === "default" ? 1170 : 0}>
        <CssBaseline />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/error/404" />
        </Switch>
      </SuiBox>
    </ThemeProvider>
  );
}
