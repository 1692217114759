/* eslint-disable no-shadow */

import ActionTypes from "constants/ActionTypes";

export function render() {
  return {
    type: ActionTypes.APP_RENDER,
  };
}

export function init() {
  return {
    type: ActionTypes.APP_INIT,
  };
}

export function initFailed(error) {
  return {
    type: ActionTypes.APP_INIT_FAILED,
    payload: {
      error,
    },
  };
}

export function loadingOn() {
  return {
    type: ActionTypes.APP_LOADING_START,
  };
}

export function loadingOff() {
  return {
    type: ActionTypes.APP_LOADING_STOP,
  };
}

export function error(_error) {
  return {
    type: ActionTypes.APP_ERROR,
    payload: {
      error: _error,
    },
  };
}

export function errorClose() {
  return {
    type: ActionTypes.APP_ERROR_CLOSE,
  };
}

export function info(_info) {
  return {
    type: ActionTypes.APP_INFO,
    payload: {
      info: _info,
    },
  };
}

export function infoClose() {
  return {
    type: ActionTypes.APP_INFO_CLOSE,
  };
}

export function refLink(values, callback) {
  const { mydappwallet } = window;
  mydappwallet.reflink(values, callback);
  return {
    type: ActionTypes.APP_REFLINK,
  };
}

export function changeSettingsSuccess(settings) {
  return {
    type: ActionTypes.APP_CHANGE_SETTINGS_SUCCESS,
    payload: {
      settings,
    },
  };
}

export function changeSettings(settings, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.change_settings(settings, (error) => {
      dispatch(changeSettingsSuccess(settings));
      callback(error, settings);
    });
  };
}

export function changeSetting(name, value, callback) {
  return (dispatch, getState) => {
    const settings = { ...getState().appStore.settings };
    settings[name] = value;
    const { mydappwallet } = window;
    mydappwallet.change_settings(settings, (error) => {
      dispatch(changeSettingsSuccess(settings));
      if (callback) callback(error, settings);
    });
  };
}

export function connect(uid, id, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.connect({ uid, id }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_CONNECT,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function confirm(id, code, callback) {
  return (dispatch) => {
    try {
      const { mydappwallet } = window;
      mydappwallet.confirm({ id, code }, (error, result) => {
        callback(error, result);
        dispatch({
          type: ActionTypes.APP_CONFIRM,
          payload: {
            id,
            hash: result,
            error,
          },
        });
        callback(error, result);
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.APP_CONFIRM,
        payload: {
          id,
          error,
        },
      });
      callback(error);
    }
  };
}

export function pay(id, values, callback) {
  return (dispatch) => {
    try {
      const { mydappwallet } = window;
      mydappwallet.pay({ id, ...values }, (error, result) => {
        dispatch({
          type: ActionTypes.APP_PAY,
          payload: {
            id,
            error,
            result,
          },
        });
        callback(error, result);
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.APP_PAY,
        payload: {
          id,
          error,
        },
      });
      callback(error);
    }
  };
}

export function reject(type, id, callback) {
  return (dispatch) => {
    try {
      const { mydappwallet } = window;
      mydappwallet.reject({ type, id }, (error, result) => {
        callback(error, result);
        dispatch({
          type: ActionTypes.APP_REJECT,
          payload: {
            id,
            result,
            error,
          },
        });
        callback(error, result);
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.APP_REJECT,
        payload: {
          id,
          error,
        },
      });
      callback(error);
    }
  };
}

export function application(uid, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    mydappwallet.app({ uid }, (error, result) => {
      dispatch({
        type: ActionTypes.APP_APPLICATION,
        payload: {
          result,
          error,
        },
      });
      callback(error, result);
    });
  };
}

export function transaction(id, callback) {
  return (dispatch) => {
    const { mydappwallet } = window;
    let intervalId;
    mydappwallet.transaction({ id }, (error, result) => {
      if (result) {
        if (result.status === 1) {
          const interval = () => {
            mydappwallet.transaction({ id }, (error, result) => {
              if (result) {
                if (result.status !== 1) {
                  dispatch({
                    type: ActionTypes.APP_TRANSACTION_SUCCESS,
                    payload: {
                      transaction: result,
                    },
                  });

                  if (result.status === 3) dispatch(error(result.error));
                  if (result.status === 4) dispatch(info({ code: 3000, data: result }));
                  clearInterval(intervalId);
                }
              }
            });
          };
          intervalId = setInterval(interval, 1000);
        }
        dispatch({
          type: ActionTypes.APP_TRANSACTION_SUCCESS,
          payload: {
            transaction: result,
          },
        });
      }
      callback(error, result);
    });
  };
}
