/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { PropTypes } from "prop-types";
// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// Soft UI Dashboard PRO React components
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import SandBox from "layouts/confirm/Payment/components/ChoosePayment/components/SandBox";
import Crypto from "layouts/confirm/Payment/components/ChoosePayment/components/Crypto";

function ChoosePayment({ transaction, select, handleClose, setPayment }) {
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState(0);

  const handleBack = () => {
    handleClose();
    window.close();
  };

  return (
    <Box sx={{ margin: 2 }}>
      <SuiTypography component="p" variant="h4" align="center" width="100%" color="info">
        Please choice the payment method
      </SuiTypography>
      <Crypto transaction={transaction} select={setSelected} selected={selected === 0} />
      {Object.keys(transaction.payments).map((key) => {
        switch (transaction.payments[key].type) {
          case 1:
            return (
              <SandBox
                payment={transaction.payments[key]}
                select={setSelected}
                selected={transaction.payments[key].type === selected}
                setPayment={setPayment}
              />
            );
          default:
            return null;
        }
      })}

      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item xs={5} md={6}>
          <SuiTypography component="label" variant="caption" textTransform="capitalize" ml={3}>
            <Trans values={{ one: 1, two: 3 }}>step-of</Trans>
          </SuiTypography>
        </Grid>
        <Grid item xs={2} md={2}>
          <SuiTypography align="right" variant="caption">
            <Link
              underline="hover"
              onClick={() => {
                handleBack();
              }}
              align="center"
            >
              <Trans>cancel</Trans>
            </Link>
          </SuiTypography>
        </Grid>

        <Grid item xs={4} md={3} pr={2}>
          <SuiButton
            name="submit"
            component={SuiButton}
            onClick={() => {
              select(selected);
            }}
            variant="gradient"
            color="info"
            fullWidth
          >
            <Trans>select</Trans>
          </SuiButton>
        </Grid>
      </Grid>
    </Box>
  );
}

ChoosePayment.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    payments: PropTypes.string.isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    type: PropTypes.number,
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
  }).isRequired,
  select: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired,
};

export default withTranslation()(ChoosePayment);
