/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// react-router components
import { useDispatch } from "react-redux";
// @mui material components
import ActionTypes from "constants/ActionTypes";

import { withTranslation } from "react-i18next";

// Soft UI Dashboard PRO React components

// Soft UI Dashboard PRO React example components
// Soft UI Dashboard PRO React page layout routes

import SignIn from "layouts/auth/sign-in";
import SignUp from "layouts/auth/sign-up";
import SignUp2 from "layouts/auth/sign-up2";
import SignUp3 from "layouts/auth/sign-up3";
import Reset from "layouts/auth/reset-password";

function Auth() {
  const dispatch = useDispatch();
  const [view, changeView] = useState("sign-in");
  const [refId, setRefId] = useState(false);

  useEffect(() => {
    dispatch({
      type: ActionTypes.APP_LOADING_STOP,
    });
  }, []);

  switch (view) {
    case "sign-up":
      return <SignUp changeView={changeView} setRefId={setRefId} />;
    case "sign-up2":
      return <SignUp2 refId={refId} changeView={changeView} />;
    case "sign-3":
      return <SignUp3 refId={refId} changeView={changeView} />;
    case "reset-password":
      return <Reset changeView={changeView} />;
    default:
      return <SignIn changeView={changeView} />;
  }
}

export default withTranslation()(Auth);
