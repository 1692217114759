/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// react-router components
import Theme from "Theme";
import { useDispatch } from "react-redux";

// i18m
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "locales/en/common.json";
import infosEN from "locales/en/infos.json";
import errorsEN from "locales/en/errors.json";
import yupEN from "locales/en/yup.json";
import currenciesEN from "locales/en/currencies.json";
import Spinner from "components/layout/Spinner";

import * as app from "actions/app";

const MyDappWalletManager = require("mydappwallet");

const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

const resources = {
  en: {
    translation: commonEN,
    infos: infosEN,
    errors: errorsEN,
    yup: yupEN,
    currencies: currenciesEN,
  },
};

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    if (window.cancel) return;
    const { mydappwallet } = window;
    const params = location.pathname.split("/");
    switch (params[1]) {
      case "confirm":
        dispatch(app.reject("transaction", parseInt(params[2], 10), () => {}));
        mydappwallet.event(
          { id: parseInt(params[3], 10), target: "mdw-inpage" },
          { code: 4500, message: "Transaction rejected by user" }
        );
        break;
      default:
        mydappwallet.event(
          { id: parseInt(params[3], 10), target: "mdw-inpage" },
          { code: 4500, message: "Rejected by user!" }
        );
    }
    //
  };

  useEffect(() => {
    bindEvent(window, "beforeunload", handleClose);
    i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      });
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

    // bindEvent(window, "beforeunload", handleClose);

    window.mydappwallet = new MyDappWalletManager(
      "https://api.mydappwallet.com",
      /* "http://localhost/api", */
      "Rczx3k42DgnBcN4A92EA",
      {
        withCredentials: false,
        redirect: (to) => {
          window.open(to);
        },
      },
      (_error, result) => {
        if (result) {
          dispatch(app.init());
        } else {
          dispatch(app.initFailed(_error));
        }
        setLoading(false);
      }
    );
  }, []);

  if (loading) return <Spinner />;
  return <Theme />;
}
