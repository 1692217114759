/* eslint-disable react/jsx-props-no-spreading */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import FormHelperText from "@mui/material/FormHelperText";
import Paper from "@mui/material/Paper";
import "components/sui/SuiMobilePhoneInput/style.css";
import PhoneInput from "react-phone-number-input";

// @mui material components
import FormControl from "@mui/material/FormControl";

// Soft UI Dashboard PRO React components

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

function SuiFormField({ touched, error, helperText, ...rest }) {
  const [highlight, setHighlight] = useState(false);

  let elevation;
  if (touched && error) {
    elevation = highlight ? 13 : 12;
  } else {
    elevation = highlight ? 11 : 10;
  }

  return (
    <>
      <Paper
        elevation={elevation}
        p={2}
        sx={{
          display: "flex-direction",
          borderRadius: borders.borderRadius.lg,
          boxShadow: 0,
          position: "relative",
          "&:hover": { outline: 0 },
        }}
      >
        <FormControl fullWidth sx={{ padding: 1 }}>
          <PhoneInput
            {...rest}
            onBlur={() => {
              setHighlight(false);
            }}
            onFocus={() => {
              setHighlight(true);
            }}
          />
        </FormControl>
      </Paper>
      <FormHelperText error id="error">
        {error ? helperText : ""}
      </FormHelperText>
    </>
  );
}

// Setting default values for the props of FormField
SuiFormField.defaultProps = {
  touched: false,
  error: false,
  helperText: "",
};

// Typechecking props for FormField
SuiFormField.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helperText: PropTypes.string,
};

export default SuiFormField;
