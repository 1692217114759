/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";
import getSymbolFromCurrency from "currency-symbol-map";
// Frame layout components

// Actions
// eslint-disable-next-line no-unused-vars
import * as app from "actions/app";

import * as CryptoUtil from "utils/CryptoUtil";

// Constrants
import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;

const utils = require("web3-utils");

function Final({ transaction, setTransaction, t }) {
  const dispatch = useDispatch();
  const [more, setMore] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (transaction.status === TRANSACTION_STATUSES.PENDING) {
      setReload(
        setInterval(() => {
          dispatch(
            app.transaction(parseInt(transaction.id, 10), (_error, result) => {
              if (result) {
                setTransaction(result);
                if (result.status !== TRANSACTION_STATUSES.PENDING) {
                  clearInterval(reload);
                }
              }
            })
          );
        }, 1000)
      );
    }
  }, []);

  const gasPrice = utils.toBN(transaction.gasPrice);
  const gasLimit = utils.toBN(transaction.gasLimit);
  const fee = gasPrice.mul(gasLimit);
  return (
    <Box sx={{ margin: 2 }}>
      {transaction.status === TRANSACTION_STATUSES.PENDING && (
        <SuiTypography component="p" variant="h5" align="center" width="100%">
          <Trans>transaction-status-description-1</Trans>
        </SuiTypography>
      )}
      {transaction.status === TRANSACTION_STATUSES.REJECTED && (
        <SuiTypography component="p" variant="h5" align="center" noWrap width="100%" color="error">
          <Trans>{`errors:${transaction.error.code}`}</Trans>
        </SuiTypography>
      )}
      {transaction.status === TRANSACTION_STATUSES.FAILED && (
        <SuiTypography
          component="p"
          variant="h5"
          align="center"
          noWrap
          width="100%"
          color="warning"
        >
          <Trans>{`errors:${transaction.error.code}`}</Trans>
        </SuiTypography>
      )}

      {transaction.status === TRANSACTION_STATUSES.FINISHED && (
        <SuiTypography component="p" variant="h5" align="center" noWrap width="100%">
          <Trans>transaction-status-description-4</Trans>
        </SuiTypography>
      )}

      <TableContainer component={Box}>
        <Table aria-label="collapsible table">
          <TableBody>
            <TableRow>
              <TableCell>
                <h4>
                  <Trans>transaction-type</Trans>
                </h4>
              </TableCell>
              <TableCell>
                <Trans
                  values={{
                    symbol: transaction.symbol ? transaction.symbol : transaction.network.symbol,
                  }}
                >{`transaction-type-${transaction.type}`}</Trans>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h4>
                  <Trans>amount</Trans>
                </h4>
              </TableCell>
              <TableCell>
                <h4>
                  {CryptoUtil.toAmount(transaction.value, transaction.decimals)}{" "}
                  {transaction.symbol ? transaction.symbol : transaction.network.symbol}
                  {transaction.payment &&
                    ` (${transaction.payment.amount} ${getSymbolFromCurrency(
                      transaction.payment.currency
                    )})`}
                </h4>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h4>
                  <Trans>status</Trans>
                </h4>
              </TableCell>
              <TableCell align="left">
                <>
                  {transaction.status === TRANSACTION_STATUSES.PENDING && (
                    <SuiButton variant="gradient" color="info" disabled>
                      <Trans>transaction-status-1</Trans>
                    </SuiButton>
                  )}
                  {transaction.status === TRANSACTION_STATUSES.REJECTED && (
                    <SuiButton variant="gradient" color="warning" disabled>
                      <Trans>transaction-status-2</Trans>
                    </SuiButton>
                  )}
                  {transaction.status === TRANSACTION_STATUSES.FAILED && (
                    <SuiButton variant="gradient" color="error" disabled>
                      <Trans>transaction-status-3</Trans>
                    </SuiButton>
                  )}
                  {transaction.status === TRANSACTION_STATUSES.FINISHED && (
                    <SuiButton variant="gradient" color="success" disabled>
                      <Trans>transaction-status-4</Trans>
                    </SuiButton>
                  )}
                </>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>from-wallet</Trans>
              </TableCell>
              <TableCell>
                {transaction.from}{" "}
                <SuiIconCopyToClipboard text={transaction.from} name={t("from")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>to-wallet</Trans>
              </TableCell>
              <TableCell>
                {transaction.to} <SuiIconCopyToClipboard text={transaction.to} name={t("to")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h4>
                  <Trans>network-fee</Trans>
                </h4>
                <Trans>equivalent</Trans>
              </TableCell>
              <TableCell>
                <h4>
                  {utils.fromWei(transaction.gasPrice.toString())} {transaction.network.symbol} (
                  {utils.fromWei(transaction.gasPrice.toString(), "Gwei")} Gwei)
                </h4>
              </TableCell>
            </TableRow>
            {transaction.transactionHash && (
              <TableRow>
                <TableCell>
                  <Trans>hash</Trans>
                </TableCell>
                <TableCell>
                  <Link
                    sx={{ focusVisible: { outline: "none" } }}
                    href={crypto.scan + transaction.transactionHash}
                    target="_blank"
                    underline="none"
                  >
                    {transaction.transactionHash.slice(0, 15)} ...{" "}
                    {transaction.transactionHash.slice(-15)}
                  </Link>{" "}
                  <SuiIconCopyToClipboard text={transaction.transactionHash} name={t("hash")} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Collapse in={more} timeout="auto" unmountOnExit>
          <>
            {" "}
            <Table size="small" aria-label="purchases">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: "30%" }}>
                    <Trans>gasLimit</Trans>
                  </TableCell>
                  <TableCell>{transaction.gasLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>gasPrice</Trans>
                  </TableCell>
                  <TableCell>
                    {utils.fromWei(transaction.gasPrice.toString())} {transaction.network.symbol} (
                    {utils.fromWei(transaction.gasPrice.toString(), "Gwei")} Gwei)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        </Collapse>
        <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
          <Link
            sx={{ focusVisible: { outline: "none" } }}
            href="#"
            onClick={() => {
              setMore(!more);
            }}
          >
            <Trans>{more ? "see-less" : "see-more"}</Trans>{" "}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setMore(!more);
              }}
            >
              {more ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Link>
        </SuiBox>
      </TableContainer>
      <Divider variant="middle" />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <SuiTypography component="label" variant="caption" textTransform="capitalize" ml={3}>
          <Trans values={{ one: 3, two: 3 }}>step-of</Trans>
        </SuiTypography>
        <Grid item xs={5}>
          {transaction.status === TRANSACTION_STATUSES.PENDING && (
            <SuiButton
              name="submit"
              component={SuiButton}
              onClick={() => {}}
              variant="gradient"
              color="primary"
              fullWidth
              pr={2}
              disabled
            >
              <Trans>wait</Trans>
            </SuiButton>
          )}
          {transaction.status !== TRANSACTION_STATUSES.PENDING && (
            <SuiButton
              name="submit"
              component={SuiButton}
              onClick={() => {
                window.close();
              }}
              variant="gradient"
              color="info"
              fullWidth
              pr={2}
            >
              <Trans>finish</Trans>
            </SuiButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
Final.defaultProps = {
  transaction: {
    payment: null,
  },
};
Final.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    transactionHash: PropTypes.string.isRequired,
    error: PropTypes.shape({ code: PropTypes.number.isRequired }),
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
    payment: PropTypes.shape({
      currency: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  }),
  setTransaction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Final);
