/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
// i18n
import { Trans, withTranslation } from "react-i18next";

// @mui material components
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";

// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiIconCopyToClipboard from "components/sui/SuiIconCopyToClipboard";
import SuiCodeInput from "components/sui/SuiCodeInput";

// Frame layout components
import Error from "components/layout/Error";

import { Formik } from "formik";

import * as Yup from "yup";

// Actions
import * as app from "actions/app";

import * as CryptoUtil from "utils/CryptoUtil";

// Constrants
import Constants from "constants/Constants";

const { TRANSACTION_STATUSES } = Constants;
const { TRANSACTION_TYPES } = Constants;
const utils = require("web3-utils");

const initialValues = {
  code: "",
};

function Crypto({ transaction, nextStage, handleClose, handleBack, t }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [submit, setSubmit] = useState(false);
  const [more, setMore] = useState(false);
  const formik = useRef(null);
  const codeInput = useRef(null);
  const [submitCounter, setSubmitCounter] = useState(0);
  const [reject, setReject] = useState(false);
  const handleChangeCode = (e) => {
    formik.current.setFieldValue("code", e);
  };

  const handleCancel = () => {
    handleClose();
    window.close();
  };

  const validationSchema = () =>
    Yup.object().shape({
      code: Yup.string()
        .required(t("yup-required", { name: t("code") }))
        .min(6),
    });

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  const onSubmited = (error, result) => {
    if (error) {
      switch (error.code) {
        case 8100:
          nextStage({
            ...transaction,
            hash: result,
            error,
            status: TRANSACTION_STATUSES.REJECTED,
          });
          return;
        case 8200:
          formik.current.setFieldValue("code", initialValues.code, false);
          codeInput.current.reset();
          if (submitCounter > 1) {
            setReject(true);
          }
          formik.current.errors.code = t(`errors:${error.code}`);
          break;
        case 8300:
          nextStage({
            ...transaction,
            hash: result,
            error,
            status: TRANSACTION_STATUSES.REJECTED,
          });
          return;
        default:
          nextStage({
            ...transaction,
            hash: result,
            error,
            status: TRANSACTION_STATUSES.FAILED,
          });
          return;
      }
    } else {
      nextStage({ ...transaction, hash: result, status: TRANSACTION_STATUSES.PENDING });
    }
    setSubmit(false);
  };

  const onSubmit = (values) => {
    setSubmit(true);
    setSubmitCounter(submitCounter + 1);
    dispatch(app.confirm(transaction.id, values.code, onSubmited));
  };

  let amount = 0;
  amount = <>{CryptoUtil.toAmount(transaction.value, transaction.decimals)}</>;

  const symbol =
    transaction.type === TRANSACTION_TYPES.TRANSFER_TOKEN
      ? transaction.symbol
      : transaction.network.symbol;

  const gasPrice = utils.toBN(transaction.gasPrice);
  const gasLimit = utils.toBN(transaction.gasLimit);
  const fee = gasPrice.mul(gasLimit);

  return (
    <Box sx={{ margin: 2 }}>
      <SuiTypography component="p" variant="h5" align="center" width="100%">
        Are you sure you want to
      </SuiTypography>
      <SuiTypography component="p" variant="h4" align="center" width="100%" color="info">
        {transaction.type === TRANSACTION_TYPES.SEND && (
          <>
            <Trans>send</Trans> {amount} {symbol}
          </>
        )}
        {transaction.type === TRANSACTION_TYPES.TRANSFER_TOKEN && (
          <>
            <Trans>transfer</Trans> {amount} {symbol}
          </>
        )}
        {transaction.type === TRANSACTION_TYPES.CONTRACT_INTERACTION && (
          <>
            <Trans>interact with contract</Trans>
          </>
        )}
        ?
      </SuiTypography>
      <TableContainer component={Box}>
        <Table aria-label="collapsible table">
          <TableBody>
            {" "}
            <TableRow>
              <TableCell sx={{ width: 100 }}>
                <h4>
                  <Trans>amount</Trans>
                </h4>
              </TableCell>
              <TableCell>
                <h4>
                  {amount} {symbol}
                </h4>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>from-wallet</Trans>
              </TableCell>
              <TableCell>
                {transaction.from}{" "}
                <SuiIconCopyToClipboard text={transaction.from} name={t("from")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>to-wallet</Trans>
              </TableCell>
              <TableCell>
                {transaction.to} <SuiIconCopyToClipboard text={transaction.to} name={t("to")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h4>
                  <Trans>network-fee</Trans>
                </h4>
                <Trans>equivalent</Trans>
              </TableCell>
              <TableCell>
                <h4>
                  {utils.fromWei(fee)} {transaction.network.symbol}
                </h4>
                {utils.fromWei(fee, "Gwei")} Gwei
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Collapse in={more} timeout="auto" unmountOnExit>
          <>
            {" "}
            <Table aria-label="more">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: 100 }}>
                    <Trans>gasLimit</Trans>
                  </TableCell>
                  <TableCell>{transaction.gasLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: 100 }}>
                    <Trans>gasPrice</Trans>
                  </TableCell>
                  <TableCell>
                    {utils.fromWei(transaction.gasPrice.toString())} {transaction.network.symbol} (
                    {utils.fromWei(transaction.gasPrice.toString(), "Gwei")} Gwei)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        </Collapse>
        <SuiBox pt="0.75rem" pl="1rem" pb="0.2rem">
          <SuiTypography component="label" variant="caption" textTransform="capitalize">
            <Link
              sx={{ focusVisible: { outline: "none" } }}
              href="#"
              onClick={() => {
                setMore(!more);
              }}
            >
              <Trans>{more ? "see-less" : "see-more"}</Trans>{" "}
              <IconButton aria-label="expand row" size="small" onClick={() => setMore(!more)}>
                {more ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Link>
          </SuiTypography>
        </SuiBox>
      </TableContainer>
      <Divider variant="middle" />
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        innerRef={formik}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <SuiBox component="form" onSubmit={handleSubmit} noValidate name="confirmForm">
            {reject ? (
              <Error error={{ code: 4400 }} color="primary" />
            ) : (
              <>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item>
                    <PhonelinkLockIcon fontSize="large" />
                  </Grid>
                  <Grid item>
                    <SuiTypography variant="caption" component="div" sx={{ fontSize: 12 }}>
                      Enter the secret code from your Authenticator to confirm the transaction.{" "}
                      <br />
                      Please open the Google Authenticator application on your mobile phone.
                    </SuiTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} wrap="nowrap">
                  <Grid item ml={7} xs={12}>
                    <SuiCodeInput
                      name="code"
                      type="number"
                      ref={codeInput}
                      fields={6}
                      value={values.code}
                      onChange={handleChangeCode}
                      isValid={!(touched.code && errors.code)}
                      filterChars={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                      filterCharsIsWhitelist
                      pattern="[0-9]"
                      helperText={errors.code}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Divider variant="middle" />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Grid item xs={5} md={6}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  textTransform="capitalize"
                  ml={3}
                >
                  <Trans values={{ one: 2, two: 3 }}>step-of</Trans>
                </SuiTypography>
              </Grid>
              <Grid item xs={2} md={2}>
                <SuiTypography align="right" variant="caption">
                  <Link
                    underline="hover"
                    onClick={() => {
                      if (transaction.payments) handleBack();
                      else handleCancel();
                    }}
                    align="center"
                  >
                    <Trans> {transaction.payments ? "back" : "cancel"}</Trans>
                  </Link>
                </SuiTypography>
              </Grid>

              <Grid item xs={4} md={3} pr={2}>
                {reject ? (
                  <SuiButton
                    name="submit"
                    disabled={submit}
                    component={SuiButton}
                    onClick={() => {
                      handleBack();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    <Trans>cancel</Trans>
                  </SuiButton>
                ) : (
                  <SuiButton
                    name="submit"
                    disabled={submit}
                    component={SuiButton}
                    onClick={() => {
                      formik.current.submitForm();
                    }}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    <Trans>confirm</Trans>
                  </SuiButton>
                )}
              </Grid>
            </Grid>
          </SuiBox>
        )}
      </Formik>
    </Box>
  );
}

Crypto.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    type: PropTypes.number,
    payments: PropTypes.oneOfType(PropTypes.object).isRequired,
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
  }).isRequired,
  nextStage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Crypto);
