/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { PropTypes } from "prop-types";
// i18n
import { withTranslation } from "react-i18next";

import ChoosePayment from "layouts/confirm/Payment/components/ChoosePayment";
import Crypto from "layouts/confirm/Payment/components/Crypto";
import SandBox from "layouts/confirm/Payment/components/SandBox";

function Payment({ transaction, nextStage, errorStage, handleClose, setTransaction }) {
  const [selected, setSelected] = useState(transaction.payments ? false : 0);

  const setPayment = (payment) => {
    const payments = { ...transaction.payments };
    payments[payment.type] = payment;
    setTransaction({ ...transaction, payments });
  };

  const handleBack = () => {
    setSelected(transaction.payments ? false : 0);
  };
  switch (selected) {
    case 0:
      return (
        <Crypto
          transaction={transaction}
          nextStage={nextStage}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      );
    case 1:
      return (
        <SandBox
          transaction={transaction}
          nextStage={nextStage}
          errorStage={errorStage}
          handleClose={handleClose}
          handleBack={handleBack}
        />
      );
    default:
      return (
        <ChoosePayment
          transaction={transaction}
          handleClose={handleClose}
          select={setSelected}
          setPayment={setPayment}
        />
      );
  }
}

Payment.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    payments: PropTypes.oneOfType(PropTypes.object).isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    type: PropTypes.number,
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
  }).isRequired,
  nextStage: PropTypes.func.isRequired,
  errorStage: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setTransaction: PropTypes.func.isRequired,
};

export default withTranslation()(Payment);
