import React from "react";

import { PropTypes } from "prop-types";
// i18n
import { withTranslation } from "react-i18next";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Images

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

import * as CryptoUtil from "utils/CryptoUtil";

const { borderWidth, borderColor } = borders;

function Crypto({ transaction, select, selected }) {
  return (
    <SuiBox
      border={`${borderWidth[selected ? 2 : 0]} solid ${borderColor}`}
      borderRadius="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={3}
      mt={2}
      onClick={() => {
        select(0);
      }}
    >
      <SuiBox alt="master card" width="10%" mr={2}>
        <div className="currency-icon" style={{ backgroundColor: "#e91550" }}>
          <span className="icon icon-eth" />
        </div>
      </SuiBox>
      <SuiTypography variant="h6" fontWeight="medium">
        {CryptoUtil.toAmount(transaction.value, transaction.decimals)} {transaction.network.symbol}
      </SuiTypography>
      <SuiBox ml="auto" lineHeight={0}>
        <Tooltip title="We do not store card details" placement="bottom">
          <SuiButton variant="outlined" color="secondary" size="small" iconOnly circular>
            <Icon sx={{ cursor: "pointer" }}>priority_high</Icon>
          </SuiButton>
        </Tooltip>
      </SuiBox>
    </SuiBox>
  );
}

Crypto.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    payments: PropTypes.string.isRequired,
    gasPrice: PropTypes.number.isRequired,
    gasLimit: PropTypes.number.isRequired,
    type: PropTypes.number,
    network: PropTypes.shape({ symbol: PropTypes.string.isRequired }),
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
};

export default withTranslation()(Crypto);
