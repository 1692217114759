/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Box from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export default styled(Box)(() => ({
  fontFamily: "'coins', sans-serif !important",
  speak: "none",
  fontStyle: "normal",
  fontWeight: "normal",
  fontVariant: "normal",
  textTransform: "none",
  lineHeight: "1",
  WebkitFontSmoothing: "antialiased",
  MozOsxFontSmoothing: "grayscale",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "36px !important",
  height: "36px !important",
  fontSize: "20px !important",
  color: "#fff !important",
}));
