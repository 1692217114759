/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";
// i18n
import { withTranslation } from "react-i18next";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import { ArrowDropDown } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import CurrencyMenu from "components/layout/CurrencyMenu";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import ColorButton from "layouts/confirm/Payment/components/styles/index";
// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import Constants from "constants/Constants";

const COUNTRIES = Constants.CURRENCIES_COUNTRIES_CODES;

const { borderWidth, borderColor } = borders;

function SandBox({ payment, select, selected, setPayment }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectCurrencyDropdown, setSelectCurrencyDropdown] = useState(false);
  // const [currency, setCurrency] = useState("EUR");
  const [countries, setCountries] = useState({});
  const handleSelectCurrency = (currency) => {
    setSelectCurrencyDropdown(false);
    setAnchorEl(null);
    if (currency) {
      // setCurrency(newCurrency);
      setPayment({ ...payment, currency });
    }
  };

  useEffect(() => {
    Object.keys(payment.amounts).map((name) => {
      countries[name] = COUNTRIES[name];
      return true;
    });
    setCountries(countries);
  }, []);

  return (
    <SuiBox
      border={`${borderWidth[selected ? 2 : 0]} solid ${borderColor}`}
      borderRadius="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={3}
      mt={2}
      onClick={() => {
        select(payment.type);
      }}
    >
      <SuiBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2} />
      <SuiTypography variant="h6" fontWeight="medium" mr={1}>
        {payment.amounts[payment.currency].amount
          .toString(10)
          .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1")}
      </SuiTypography>
      <ColorButton
        variant="contained"
        endIcon={<ArrowDropDown />}
        disableElevation
        onClick={(event) => {
          setSelectCurrencyDropdown(true);
          setAnchorEl(event.currentTarget);
        }}
      >
        {payment.currency}
      </ColorButton>
      {selectCurrencyDropdown && (
        <SuiBox sx={{ position: "absolute", zIndex: 2, top: "2.5rem", left: "1.5rem" }}>
          <CurrencyMenu anchorEl={anchorEl} close={handleSelectCurrency} countries={countries} />
        </SuiBox>
      )}

      <SuiBox ml="auto" lineHeight={0}>
        <Tooltip title="We do not store card details" placement="bottom">
          <SuiButton variant="outlined" color="secondary" size="small" iconOnly circular>
            <Icon sx={{ cursor: "pointer" }}>priority_high</Icon>
          </SuiButton>
        </Tooltip>
      </SuiBox>
    </SuiBox>
  );
}

SandBox.propTypes = {
  payment: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    amounts: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string.isRequired,
        currency: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  setPayment: PropTypes.func.isRequired,
};

export default withTranslation()(SandBox);
