/* eslint-disable react/destructuring-assignment */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

import { PropTypes } from "prop-types";

// i18n
import i18n from "i18next";
import authenticationEN from "locales/en/authentication.json";

// Views
import Stage0 from "layouts/auth/sign-in/components/stage-0";
import Stage1 from "layouts/auth/sign-in/components/stage-1";
import Stage2 from "layouts/auth/sign-in/components/stage-2";
// Images

function SignIn({ changeView }) {
  const [stage, setStage] = useState(0);
  const [refId, setRefId] = useState(false);
  const [authenticator, setAuhenticator] = useState(false);
  const [oneTime, setOneTime] = useState(true);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
  }, []);

  const nextStage = (_refId, _authenticator = false) => {
    setRefId(_refId);
    setAuhenticator(_authenticator);
    setAuhenticator(_authenticator);
    setStage(stage + 1);
  };
  const cancel = () => {
    setStage(0);
    setRefId(false);
    setAuhenticator(false);
    setOneTime(true);
  };

  switch (stage) {
    case 1:
      return <Stage1 refId={refId} nextStage={nextStage} setOneTime={setOneTime} />;
    case 2:
      return (
        <Stage2
          refId={refId}
          authenticator={authenticator}
          nextStage={nextStage}
          oneTime={oneTime}
          cancel={cancel}
        />
      );
    default:
      return <Stage0 changeView={changeView} nextStage={nextStage} />;
  }
}

SignIn.propTypes = {
  changeView: PropTypes.func.isRequired,
};

export default SignIn;
