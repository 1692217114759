/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// react-router-dom components
import { Redirect } from "react-router-dom";

// PropTypes
import PropTypes from "prop-types";

// i18n
import i18n from "i18next";
import { Trans, withTranslation } from "react-i18next";
import authenticationEN from "locales/en/authentication.json";

import * as Yup from "yup";
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";
import SuiInput from "components/sui/SuiInput";
import SuiSpinner from "components/sui/SuiSpinner";

// Authentication layout components
import BasicLayout from "layouts/components/BasicLayout";
import Error from "components/layout/Error";
import Spinner from "components/layout/Spinner";
// Images
import curved8 from "assets/images/curved-images/curved8.jpg";

import * as authentication from "actions/authentication";
import * as app from "actions/app";

import Constants from "constants/Constants";

const { REFLINK_TYPES } = Constants;

function SignUp3({ refId, changeView, t }) {
  const dispatch = useDispatch();
  const appStore = useSelector((state) => state.appStore);

  const [initialValues] = useState({
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formik = useRef(null);

  useEffect(() => {
    i18n.addResources("en", "authentication", authenticationEN);
    dispatch(
      app.refLink({ refId, type: REFLINK_TYPES.CREATE_PROFILE }, (error) => {
        if (error) {
          setRedirect(`/error/${error.code}`);
        } else {
          setLoading(false);
        }
      })
    );
  }, []);

  const onSubmited = (_error) => {
    if (_error) {
      switch (_error.code) {
        case 3000:
          formik.current.setFieldValue("email", "", false);
          formik.current.errors.email = t("yup:email");
          break;
        case 3100:
          formik.current.setFieldValue("username", "", false);
          formik.current.errors.username = t("errors:3100");
          break;
        case 3200:
          formik.current.setFieldValue("email", "", false);
          formik.current.errors.email = t("errors:3200");
          break;
        case 3300:
          formik.current.setFieldValue("email", "", false);
          formik.current.errors.email = t("yup:email");
          dispatch(app.error(_error));
          break;
        case 3500:
          dispatch(app.error({ code: 50000 }));
          changeView("sign-up");
          break;
        case 8300:
          dispatch(app.error(_error));
          changeView("sign-in");
          break;
        default:
          dispatch(app.error(_error));
      }
      setSubmit(false);
    } else {
      setRedirect("/frames/crypto/mainnet");
    }
  };

  const onSubmit = (values) => {
    setSubmit(true);
    dispatch(app.infoClose());
    dispatch(app.errorClose());
    dispatch(authentication.signUp3({ ...values, refId }, onSubmited));
    return true;
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce(
      (errors, _error) => ({
        ...errors,
        [_error.path]: _error.errors[FIRST_ERROR],
      }),
      {}
    );
  };

  const validationSchema = () =>
    Yup.object().shape({
      username: Yup.string()
        .min(3, t("yup:min", { name: t("username"), char: 3 }))
        .required(t("yup:required", { name: t("username") })),
      password: Yup.string()
        .required(t("yup:required", { name: t("password") }))
        .min(8, t("yup:min", { name: t("password"), char: 8 })),
      passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], t("yup:password")),
      email: Yup.string()
        .required(t("yup:required", { name: t("email") }))
        .email(t("yup:email")),
    });

  const validate = (getValidationSchema) => (values) => {
    const validationSchemaInst = getValidationSchema(values);
    try {
      validationSchemaInst.validateSync(values, { abortEarly: false });
      return {};
    } catch (_error) {
      return getErrorsFromValidationError(_error);
    }
  };

  if (redirect) return <Redirect to={redirect} />;
  if (loading) return <Spinner />;
  return (
    <BasicLayout
      title={t("authentication:signup3.title")}
      description={t("authentication:signup3.subtitle")}
      image={curved8}
    >
      <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
        <Formik
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={onSubmit}
          innerRef={formik}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Card>
              <Form onSubmit={handleSubmit} noValidate name="registerForm">
                <SuiBox p={3} mb={1} textAlign="center">
                  <SuiTypography variant="h5" fontWeight="medium">
                    <Trans>authentication:create-account</Trans>
                  </SuiTypography>
                </SuiBox>
                <SuiBox pt={2} pb={3} px={3}>
                  <Error
                    error={appStore.error}
                    color="primary"
                    onClick={() => {
                      dispatch(app.errorClose());
                    }}
                  />
                  <SuiBox>
                    <SuiBox mb={2}>
                      <SuiInput
                        name="username"
                        placeholder={t("username")}
                        error={!!touched.username && !!errors.username}
                        helperText={errors.username}
                        onChange={handleChange}
                        value={values.username}
                        disabled={submit}
                        onBlur={handleBlur}
                      />
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiInput
                        name="email"
                        type="email"
                        placeholder="Email"
                        error={!!touched.email && !!errors.email}
                        helperText={errors.email}
                        onChange={handleChange}
                        value={values.email}
                        disabled={submit}
                        onBlur={handleBlur}
                      />
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiInput
                        name="password"
                        type="password"
                        placeholder={t("password")}
                        error={!!touched.password && !!errors.password}
                        helperText={errors.password}
                        onChange={handleChange}
                        value={values.password}
                        disabled={submit}
                        onBlur={handleBlur}
                      />
                    </SuiBox>
                    <SuiBox mb={2}>
                      <SuiInput
                        name="passwordConfirmation"
                        type="password"
                        placeholder={t("repeat-password")}
                        error={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                        helperText={errors.passwordConfirmation}
                        onChange={handleChange}
                        value={values.passwordConfirmation}
                        disabled={submit}
                        onBlur={handleBlur}
                      />
                    </SuiBox>
                    {submit && (
                      <SuiBox mt={1} mb={1}>
                        {" "}
                        <SuiSpinner size={50} minHeight={50} />{" "}
                      </SuiBox>
                    )}
                    <SuiBox mt={4} mb={1}>
                      <SuiButton
                        variant="gradient"
                        color="dark"
                        fullWidth
                        disabled={submit}
                        onClick={() => {
                          formik.current.submitForm();
                        }}
                      >
                        <Trans>authentication:create-account</Trans>
                      </SuiButton>
                    </SuiBox>
                    <SuiBox mt={3} textAlign="center">
                      <SuiTypography variant="button" color="text" fontWeight="regular">
                        Already have an account?&nbsp;
                        <SuiTypography
                          onClick={() => {
                            changeView("sign-in");
                          }}
                          variant="button"
                          color="dark"
                          fontWeight="bold"
                          textGradient
                          disabled={submit}
                        >
                          <Trans>authentication:signin</Trans>
                        </SuiTypography>
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>
              </Form>
            </Card>
          )}
        </Formik>
      </Grid>
    </BasicLayout>
  );
}

SignUp3.propTypes = {
  refId: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SignUp3);
